import { Input, Select, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Text from 'antd/lib/typography/Text';
import React, { useMemo, useState } from 'react';
import { usePromiseResult } from '../../hooks/usePromiseResult';
import { TableField } from '../../modules/hasura/types';
import { getInputConfig } from '../../modules/row-data';
import { TableResource } from '../../modules/row-data/getResources';
import { ColSettings, InputType } from '../../modules/row-data/types';
import { InputBaseProps } from '../../utils/common';
import getRenders from '../../utils/getRenders';
import CheckboxInput from '../inputs/CheckboxInput';
import { QuickForm } from '../QuickForm';

export type FieldEditValues = ColSettings;

export type FieldEditFormProps = InputBaseProps<FieldEditValues> & {
  field: TableField;
  tableResource: TableResource;
  onSave?: (values: FieldEditValues) => Promise<unknown>;
};

const inputTypeOptions = Object.entries(InputType).map(([key]) => {
  return {
    label: key,
    value: key,
  };
});

const { formItems, buttons } = getRenders({
  formItems: {
    title: {
      label: <Text strong>Title</Text>,
      name: 'title',
      children: <Input placeholder="Title" />,
    },
    type: {
      label: <Text strong>Field type</Text>,
      name: 'type',
      children: <Select options={inputTypeOptions} placeholder="Field type" />,
    },
    defaultHideColumn: {
      name: 'defaultHideColumn',
      children: <CheckboxInput>Default hide column</CheckboxInput>,
    },
    searchable: {
      name: 'searchable',
      children: <CheckboxInput>Searchable</CheckboxInput>,
    },
    bulkEditable: {
      name: 'bulkEditable',
      children: <CheckboxInput>Bulk editable</CheckboxInput>,
    },
  },
  buttons: {
    submit: {
      htmlType: 'submit',
      children: 'Submit',
      type: 'primary',
    },
  },
});

export default function FieldEditForm(props: FieldEditFormProps) {
  const [form] = useForm<FieldEditValues>();
  const { field, tableResource, value, onSave } = props;
  const [save, saveResult] = usePromiseResult(onSave);
  const [inputType, setInputType] = useState<InputType | undefined>(
    value?.type
  );
  const renders = useMemo(() => {
    if (!inputType) {
      return;
    }
    return getInputConfig(inputType);
  }, [inputType]);

  return (
    <QuickForm<FieldEditValues>
      form={form}
      layout="vertical"
      initialValues={value}
      onFinish={save}
      onValuesChange={(changed) => {
        if (changed.type) {
          setInputType(changed.type);
          form.setFieldValue('options', {});
        }
      }}
    >
      {formItems.title()}
      <Space>
        {formItems.defaultHideColumn()}
        {formItems.searchable()}
        {formItems.bulkEditable()}
      </Space>
      <div style={{ width: 200 }}>{formItems.type()}</div>
      {renders?.renderRenderOptions &&
        renders.renderRenderOptions(field, tableResource)}
      {buttons.submit({
        loading: saveResult.loading,
      })}
    </QuickForm>
  );
}
