import { Card, Col, Row, Space, Switch, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { TableField } from '../../modules/hasura/types';
import { getInputConfig } from '../../modules/row-data';
import { TableResource } from '../../modules/row-data/getResources';
import { ColSettings } from '../../modules/row-data/types';
import CheckboxInput from '../inputs/CheckboxInput';
import VSpace from '../VSpace';
import FieldEditForm from './FieldEditForm';

export type FieldSettingsProps = {
  field: TableField;
  tableResource: TableResource;
  values?: ColSettings;
  onSave?: (values: ColSettings) => Promise<unknown>;
};

export default function FieldSettings(props: FieldSettingsProps) {
  const [editMode, setEditMode] = useState(false);
  const { field, tableResource, values, onSave } = props;
  const renders = useMemo(() => {
    if (!values?.type) {
      return;
    }
    return getInputConfig(values.type);
  }, [values?.type]);

  return (
    <Card
      bordered={false}
      size="default"
      title={
        <Row>
          <Col flex={1}>
            <Space>
              <Typography.Text type="secondary">{field.name}</Typography.Text>
              {values?.type && (
                <Typography.Text code>{values.type}</Typography.Text>
              )}
              {values?.title && (
                <Typography.Text>{values.title}</Typography.Text>
              )}
            </Space>
          </Col>
          <Col>
            <Switch
              checkedChildren="Edit"
              unCheckedChildren="Edit"
              checked={editMode}
              onChange={setEditMode}
            />
          </Col>
        </Row>
      }
    >
      {editMode && (
        <FieldEditForm
          field={field}
          tableResource={tableResource}
          value={values}
          onSave={onSave}
        />
      )}
      {!editMode && (
        <>
          <VSpace>
            <Space>
              <CheckboxInput value={!!values?.defaultHideColumn} readOnly>
                Default hide column
              </CheckboxInput>
            </Space>
            {!!renders?.renderRenderOptionsViewMode &&
              renders.renderRenderOptionsViewMode(values?.options, field)}
          </VSpace>
        </>
      )}
    </Card>
  );
}
