import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Input, message, Space, Tabs, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { PageProps } from 'gatsby';
import { cloneDeep, debounce, defaultsDeep, pick, set, sortBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FieldEditValues } from '../../components/DataSettings/FieldEditForm';
import FieldSettings from '../../components/DataSettings/FieldSettings';

import Layout from '../../components/Layout';
import { QuickForm } from '../../components/QuickForm';

import { usePromiseResult } from '../../hooks/usePromiseResult';
import { useAppContext } from '../../modules/app';
import { IntroTable } from '../../modules/hasura/types';
import { useKeyData } from '../../modules/hasura/useKeyData';
import { DataResources } from '../../modules/row-data/getResources';
import { MenuItem } from '../../utils/common';
import createPrivatePage from '../../utils/createPrivatePage';
import SortableList from './SortableList';

export type DataSettingsTableProps = {
  introTable?: IntroTable;
  tableName: string;
};

type TableSettings = {
  title?: string;
  cols: Record<string, FieldEditValues>;
};

/**
 * Helpers to edit tableSettings[tableName]
 */
function useTableHelpers(
  tableName: string,
  resources: DataResources,
  allTableSettings: Record<string, TableSettings>
) {
  const resource = resources[tableName];
  const tableSettings = allTableSettings?.[tableName];

  /**
   * Current settings with filter out deleted cols or tables
   */
  const getCurrentSettings = useCallback(() => {
    const result: Record<string, TableSettings> = pick(
      cloneDeep(allTableSettings),
      Object.keys(allTableSettings)
    );
    for (const k in result) {
      result[k].cols = pick(
        result[k].cols,
        Object.keys(resources[k]?.ownFields || {})
      );
    }
    return result;
  }, [allTableSettings, resource]);

  /**
   * Update data and return next settings
   */
  const getNextSettings = useCallback(
    (data: {
      general?: Pick<TableSettings, 'title'>;
      cols?: TableSettings['cols'];
      sortedItems?: ReturnType<typeof getSortedItems>;
    }) => {
      const { general, cols, sortedItems = [] } = data;
      const nextSettings = getCurrentSettings();

      // cols should set updated data because col type change
      // the options should update instead of merge
      if (cols) {
        for (const k in cols) {
          set(nextSettings, [tableName, 'cols', k], cols[k]);
        }
      }
      return defaultsDeep(
        {
          [tableName]: {
            ...general,
            cols: Object.fromEntries(
              sortedItems.map((item, index) => [item.id, { sortOrder: index }])
            ),
          },
        },
        nextSettings
      );
    },
    [tableName, getCurrentSettings]
  );

  /**
   * Sorted ownFields to items render
   */
  const getSortedItems = useCallback(() => {
    return sortBy(
      Object.entries(resource.ownFields).map(([key]) => {
        return {
          id: key,
          sortOrder: tableSettings?.cols?.[key]?.sortOrder ?? 999,
        };
      }),
      'sortOrder'
    );
  }, [tableSettings, resource]);

  return { tableSettings, getNextSettings, getSortedItems };
}

const DataSettingsTable: React.FC<DataSettingsTableProps> = (props) => {
  const { tableName } = props;
  const { resources } = useAppContext();

  const tableResource = resources[tableName];
  const {
    data: allTableSettings = {},
    save,
    loading,
  } = useKeyData<any>('dataSettings.tables');
  const [saveSettings, saveResult] = usePromiseResult(save);

  useEffect(() => {
    if (saveResult.error) {
      message.error('Submit errors');
    } else if (saveResult.data) {
      message.success('Submit successful');
    }
  }, [saveResult.called]);

  const { tableSettings, getNextSettings, getSortedItems } = useTableHelpers(
    props.tableName,
    useAppContext().resources,
    allTableSettings
  );

  const [form] = useForm();
  const [sortedItems, setSortedItems] = useState(getSortedItems());

  const saveGeneral = useCallback(
    (values: { title: string }) => {
      saveSettings({
        set: getNextSettings({
          general: values,
        }),
      });
    },
    [getNextSettings, saveSettings]
  );

  /**
   * Save field settings
   */
  const saveFieldSettings = useCallback(
    async (fieldName: string, values: FieldEditValues) => {
      return saveSettings({
        set: getNextSettings({
          cols: {
            [fieldName]: values,
          },
        }),
      });
    },
    [getNextSettings, saveSettings]
  );

  /**
   * Debounce save sorted
   */
  const saveSorted = useCallback(
    debounce((items: typeof sortedItems) => {
      return saveSettings({
        set: getNextSettings({ sortedItems: items }),
      });
    }),
    [getNextSettings, saveSettings]
  );

  const renderItem = ({ id }: any, index: number) => {
    const field = tableResource.ownFields[id];
    if (!field) {
      return null;
    }
    const initialValues = tableSettings?.cols?.[id];
    return (
      <FieldSettings
        key={`${tableName}${id}`}
        field={field}
        tableResource={tableResource}
        values={initialValues}
        onSave={(values) => {
          return saveFieldSettings(id, values);
        }}
      />
    );
  };

  if (!allTableSettings && loading) {
    return null; // loading
  }

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: `General`,
            key: '1',
            children: (
              <QuickForm
                form={form}
                layout="vertical"
                onFinish={saveGeneral}
                initialValues={tableSettings}
                actionButtons={[
                  {
                    children: 'Submit',
                    htmlType: 'submit',
                    type: 'primary',
                    loading: loading,
                    disabled: loading,
                  },
                ]}
              >
                <FormItem
                  name="title"
                  label={<Typography.Text strong>Table title</Typography.Text>}
                >
                  <Input placeholder="Title" style={{ maxWidth: 200 }} />
                </FormItem>
              </QuickForm>
            ),
          },
          {
            label: `Field Settings`,
            key: '2',
            children: (
              <SortableList
                items={sortedItems}
                renderItem={renderItem}
                onChange={(items) => {
                  setSortedItems(items);
                  saveSorted(items);
                }}
              />
            ),
          },
        ]}
      />
    </>
  );
};

/**
 * Routers and menus
 */
function DataSettings(props: PageProps) {
  const { params } = props;
  const { resources } = useAppContext();

  const tables = Object.keys(resources);
  const subMenus: MenuItem[] = tables.map((name) => {
    return { title: name, path: `/data-settings/${name}` };
  });

  if (!resources[params.name]) {
    console.warn(`There no resourse found ${params.name}`);
    return null;
  }

  const { spec } = resources[params.name];
  const noCol = Object.keys(spec.fields).length;

  const SubMenus = useMemo(() => {
    const items = subMenus.map((m) => {
      return { label: m.title as string, key: m.path };
    });

    return (
      <Dropdown
        menu={{
          items,
          onClick: (v) => {
            props.navigate(v.key);
          },
        }}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            {spec.name}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    );
  }, [subMenus, spec.name]);

  return (
    <Layout
      pageTitle={`${spec.name || ''} Table`}
      pageSubTitle={`${noCol} columns`}
      breadcrumb={[
        { title: 'Home', path: '/' },
        { title: 'Data Settings', path: '/data-settings' },
        {
          title: SubMenus,
          path: `/data-settings/${spec.name}`,
        },
      ]}
      // subMenus={[
      //   {
      //     title: 'Define Tables',
      //     icon: <TableOutlined />,
      //     path: '/data-settings',
      //     subMenus,
      //   },
      // ]}
    >
      <DataSettingsTable key={params.name} tableName={params.name} />
    </Layout>
  );
}

export default createPrivatePage(DataSettings);
